 import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">

<path d="M2564 2983 l-251 -3 -121 -273 c-67 -150 -124 -281 -128 -290 -6 -16
8 -17 196 -15 l203 3 31 72 31 73 219 0 c171 0 217 -3 213 -12 -3 -7 -51 -119
-106 -248 -142 -331 -155 -355 -231 -430 -73 -73 -162 -122 -250 -138 -32 -6
-335 -13 -715 -17 -694 -7 -724 -9 -844 -55 -68 -26 -172 -94 -232 -151 -72
-69 -159 -195 -159 -230 0 -16 2144 -4 2235 13 75 13 175 64 242 123 71 62
103 120 213 380 20 50 88 207 150 350 249 575 360 836 360 845 0 9 -370 10
-1056 3z"/>
</g>
</svg>


    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}